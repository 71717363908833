<template>
  <div class="mx-1">
    <table-task-inheritance
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      noActions
      noInnerWidth
      noPagination
      @forcedFetch="forcedFetch"
    >
    </table-task-inheritance>
  </div>
</template>

<script>
import TableTaskInheritance from "@/components/tables/TasksInheritance";
import FormMixin from "@/mixins/FormMixin";
import { toInfoData } from "@/utils/transforms";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";
import { toLocaleDate } from "@/utils/dates";
import { getDictionary } from "@/utils/dictionary";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [CalendarEventsAndTasksMixin, FormMixin],
  name: "Notes",
  data() {
    return {
      entity_type: "policy",
      filter: this.initFilter(),
      filterName: "filterInsurancePolicyNoteInheritanceDetail",
      repository: "task",
      resource: "tasks",
      tableRef: "NoteDetailTableRef",
      taskable_id: this.resourceId,
      taskable: "insurance_policy",
      task_type: 2,
      task_id: null,
      formLoaded: false,
      fields: [
        {
          key: "custom_actions",
          label: "Azioni",
          sortable: false,
          thStyle: {
            width: "120px",
            //width: `${20 * this.actions.length}px`,
          },
        },
        {
          key: "insurance_policies[0].number",
          label: this.getDictionary("polizza_sostituita", "task"),
          sortable: true,
          // sortKey: "byInsurancePolicy.number",
        },
        {
          key: "insurance_policies[0].registry",
          label: this.getDictionary("contractor"),
          formatter: (value) => this.toInfoData(value, "registryfullname"),
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: "insurance_policies[0].expired_at",
          label: this.getDictionary("expired_at", "insurance_policy"),
          sortable: true,
          // sortKey: "byInsurancePolicy.expired_at",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "insurance_policies[0].broker_id",
          label: this.getDictionary("salesman"),
          formatter: (value) => this.toBrokerInfo(value),
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: "description",
          label: this.getDictionary("description", "task"),
          sortable: true,
        },
        {
          key: "insurance_policies_new_policy[0].new_policies_lite[0].number",
          label: this.getDictionary("polizza_sostituente", "task"),
          formatter: (value) => (value ? value : "-"),
          sortable: true,
        },
        {
          key: "insurance_policies_new_policy[0].new_policies_lite[0].status_policy",
          label: this.getDictionary("status_policy", "insurance_policy"),
          formatter: (value) => (value ? value.text : "-"),
          sortable: true,
        },
        {
          key: "insurance_policies_new_policy[0].new_policies_lite[0].covered_at",
          label: this.getDictionary("covered_at", "insurance_policy"),
          sortable: true,
          formatter: (value) => (value ? toLocaleDate(value) : "-"),
        },
      ],
    };
  },
  components: {
    TableTaskInheritance,
  },
  methods: {
    toInfoData,
    getDictionary,
    toBrokerInfo(id) {
      if (!id) return "-";
      return this.salesmen.find((s) => s.value == id).text ?? "-";
    },
    initFilter() {
      let init = {
        byAttribute: {
          inheritance: 2,
        },
        byInsurancePolicy: {
          status_policy: 3,
        },
      };
      return init;
    },
    forcedFetch() {
      this.onSearch();
    },
    onSearch(name) {
      // onSearch() {
      let criteria = this.filter[name];
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef]
        .fetch()
        .then(() => {
          // this.removeFilterByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      //   removeFilterByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
  },
  mounted() {
    // this.onSearch(this.filterName);
    this.onSearch();
    this.salesmen = this.getSalesmen();
  },
};
</script>
